<!-- 关于我们-公司介绍-公司地址 -->
<template>
	<div class="Address-warp">
		<div class="content">
			<div class="inner">
				<div class="title" id="boxContent">
					<span class="title-text">联系我们</span>
					<span class="title-desc">捷途慧声，期待与您合作</span>
				</div>
				<p class="address-title">杭州捷途慧声科技有限公司</p>
				<div class="address">
					<div class="address-left">
						<BaiduMap />
					</div>
					<div class="address-right" v-if="!isMobileEnd">
						<p class="address-right-img">
							<img
								v-lazy="footerGzh"
								:key="footerGzh"
								alt="公众号"
							/>
						</p>
						<p>关注公众号"捷途慧声"了解更多</p>
						<p>联系电话：0571-86929509</p>
						<p>电子邮箱：sales@jet-voice.com</p>
						<p>公司地址：杭州市余杭区利尔达物联网科技园1幢1102室</p>
					</div>
					<div class="address-768-right" v-else>
						<div class="address-768-right-img">
							<img
								v-lazy="footerGzh"
								:key="footerGzh"
								alt="公众号"
							/>
						</div>
						<div class="address-768-right-text">
							<p>杭州捷途慧声科技有限公司</p>
							<p>关注公众号"捷途慧声"了解更多</p>
							<p>联系电话：0571-86929509</p>
							<p>电子邮箱：sales@jet-voice.com</p>
							<p>
								公司地址：杭州市余杭区利尔达物联网科技园1幢1102室
							</p>
						</div>
					</div>
				</div>
				<p class="address-title">杭州捷途慧声科技有限公司沈阳分公司</p>
				<div class="address">
					<div class="address-left">
						<BaiduMapPoint />
					</div>
					<div class="address-right" v-if="!isMobileEnd">
						<p class="address-right-img">
							<img
								v-lazy="footerGzh"
								:key="footerGzh"
								alt="公众号"
							/>
						</p>
						<p>关注公众号"捷途慧声"了解更多</p>
						<p>联系电话：15502403389</p>
						<p>电子邮箱：fanb@jet-voice.com</p>
						<p>公司地址：沈阳市三好街五里河城C1单元801室</p>
					</div>
					<div class="address-768-right" v-else>
						<div class="address-768-right-img">
							<img
								v-lazy="footerGzh"
								:key="footerGzh"
								alt="公众号"
							/>
						</div>
						<div class="address-768-right-text">
							<p>杭州捷途慧声科技有限公司沈阳分公司</p>
							<p>关注公众号"捷途慧声"了解更多</p>
							<p>联系电话：15502403389</p>
							<p>电子邮箱：fanb@jet-voice.com</p>
							<p>公司地址：沈阳市三好街五里河城C1单元801室</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="left"></div>
		<div class="right"></div>
	</div>
</template>

<script>
import BaiduMap from './childCpn/BaiduMap'
import BaiduMapPoint from './childCpn/BaiduMapPoint'
export default {
	name: 'Address',
	data() {
		return {
			footerGzh: require('@/assets/img/info/gzh.png'),
		}
	},
	watch: {},
	created() {
		this.$nextTick(() => {
			this.toLocal()
		})
	},
	mounted() {
		this.$nextTick(() => {
			this.toLocal()
		})
	},
	methods: {
		toLocal() {
			let Id = this.$route.query.id
			if (Id == 'boxContent') {
				let toElement = document.getElementById(Id)
				toElement.scrollIntoView()
			}
		},
	},
	components: {
		BaiduMap,
		BaiduMapPoint,
	},
	computed: {
		isMobileEnd() {
			return this.$store.state.isMobileEnd
		},
	},

	destroyed() {},
}
</script>
<style lang="scss" scoped>
.Address-warp {
	// 三栏都需要浮动
	.center,
	.left,
	.right {
		float: left;
	}
	// 左右两栏固定宽度
	.left,
	.right {
		width: 3.15rem;
		background-color: #fff;
	}
	// 中间栏  因为需要自适应所以设置宽度100%
	.center {
		width: 100%;
	}
	.left {
		margin-left: -100%;
	}
	.right {
		margin-left: -3.15rem;
	}
	.inner {
		margin: 0 3.15rem;
	}
	.content {
		height: 12rem;
		padding: 1.18rem 0 0.65rem;
	}
	.address-title {
		font-size: 0.2rem;
		font-weight: 550;
		text-align: left;
		margin-bottom: 0.2rem;
	}
	.address {
		width: 100%;
		min-height: 4rem;
		margin-bottom: 0.4rem;
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		.address-left {
			width: 6.88rem;
			height: 4rem;
			border-radius: 0.2rem;
			overflow: hidden;
			margin-bottom: 0.2rem;
		}
		.address-right {
			margin-left: 0.2rem;
			width: 5.4rem;
			p {
				font-size: 0.14rem;
				color: #333;
				text-align: left;
				line-height: 130%;
			}
			p:nth-child(3),
			p:nth-child(4) {
				margin: 0.2rem 0;
			}
			.address-right-img {
				width: 1.4rem;
				height: 1.4rem;
				margin-bottom: 0.1rem;
				img {
					width: 100%;
					height: 100%;
				}
			}
		}
	}
}
.title {
	width: 100%;
	margin-bottom: 0.6rem;
	text-align: center;
	.title-text {
		height: 0.6rem;
		font-size: 0.52rem;
		font-family: Source Han Sans, Source Han Sans CN;
		font-weight: 500;
		color: #333333;
		line-height: 0.6rem;
		display: block;
	}
	.title-desc {
		display: inline-block;
		font-size: 0.24rem;
		font-family: Source Han Sans, Source Han Sans CN;
		font-weight: 400;
		color: #666666;
		line-height: 0.32rem;
		margin-top: 0.12rem;
	}
}
@media screen and (max-width: 768px) {
	.Address-warp {
		.left,
		.right {
			width: 16px;
		}
		.right {
			margin-left: -16px;
		}
		.inner {
			margin: 0 16px;
		}
		.content {
			height: 734px;
			padding: 24px 0;
		}
		.address-title {
			display: none;
		}
		.address {
			margin-bottom: 1.2rem;
			.address-left {
				width: 100%;
				height: 10rem;
				margin-bottom: 24px;
			}
			.address-right {
				width: 100%;
				p {
					font-size: 12px;
				}
				.address-right-img {
					width: 5rem;
					height: 5rem;
				}
			}
			.address-768-right {
				display: flex;
				justify-content: space-around;
				align-items: start;
				&-img {
					width: 100px;
					height: 100px;
				}
				&-text {
					p {
						font-size: 8px;
						line-height: 14px;
					}
					p:nth-child(1) {
						font-size: 12px;
						font-weight: 700;
						margin: 6px 0 12px;
					}
				}
			}
		}
	}
	.title {
		.title-text {
			height: 24px;
			font-size: 16px;
			line-height: 24px;
		}
		.title-desc {
			font-size: 10px;
			line-height: 18px;
			margin-top: 4px;
		}
	}
}
</style>
