<!-- 关于我们-公司介绍 -->
<template>
	<div class="AboutUs-warp">
		<div class="content" v-if="!isMobileEnd">
			<div class="AboutUs-Bg">
				<img v-lazy="aboutUsBg" :key="aboutUsBg" alt="关于我们" />
			</div>
			<div class="inner">
				<div class="AboutUs-title">关于我们</div>
				<div class="AboutUs-desc">
					<p v-for="(item, index) in companyProfile" :key="index">
						{{ item }}
					</p>
				</div>
				<div class="AboutUs-data">
					<img v-lazy="aboutUsData" :key="aboutUsData" alt="信息" />
				</div>
			</div>
		</div>
		<div class="content-768" v-else>
			<div class="content-768-Bg">
				<img v-lazy="aboutUsBg" :key="aboutUsBg" alt="关于我们" />
			</div>
			<div class="inner">
				<div class="content-768-title">关于我们</div>
				<div class="content-768-desc">
					<p v-for="(item, index) in companyProfile" :key="index">
						{{ item }}
					</p>
				</div>
				<div class="content-768-data">
					<img v-lazy="aboutUsData" :key="aboutUsData" alt="信息" />
				</div>
			</div>
		</div>
		<div class="left"></div>
		<div class="right"></div>
	</div>
</template>

<script>
import { companyProfile } from './mock'
export default {
	name: 'AboutUs',
	data() {
		return {
			aboutUsBg: require('@/assets/img/company/aboutUsBg-1.png'),
			aboutUsData: require('@/assets/img/company/homeCompanyProfileData.png'),
			companyProfile: companyProfile,
		}
	},
	watch: {},
	created() {},
	mounted() {},
	methods: {},
	components: {},
	computed: {
		isMobileEnd() {
			return this.$store.state.isMobileEnd
		},
	},

	destroyed() {},
}
</script>
<style lang="scss" scoped>
.AboutUs-warp {
	// 三栏都需要浮动
	.center,
	.left,
	.right {
		float: left;
	}
	// 左右两栏固定宽度
	.left,
	.right {
		width: 3.15rem;
		background-color: #fff;
	}
	// 中间栏  因为需要自适应所以设置宽度100%
	.center {
		width: 100%;
	}
	.left {
		margin-left: -100%;
	}
	.right {
		margin-left: -3.15rem;
	}
	.inner {
		margin: 0 3.15rem;
	}
	.content {
		width: 100%;
		height: 9rem;
		position: relative;
		.inner {
			height: 100%;
			display: flex;
			flex-direction: column;
			justify-content: center;
		}
		.AboutUs-Bg {
			width: 10.23rem;
			height: 8rem;
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center center;
			position: absolute;
			right: 0;
			bottom: 0;
		}
		.AboutUs-title {
			font-size: 0.52rem;
			font-family: Source Han Sans, Source Han Sans CN;
			font-weight: 700;
			color: #333333;
			line-height: 0.52rem;
			margin-bottom: 0.24rem;
		}
		.AboutUs-desc {
			width: 10.29rem;
			font-size: 0.16rem;
			font-family: Source Han Sans, Source Han Sans CN;
			font-weight: 400;
			color: #5e6172;
			line-height: 0.24rem;
			p {
				margin-bottom: 0.12rem;
			}
		}
		.AboutUs-data {
			width: 5.9rem;
			height: 1.08rem;
			border: 1px solid #f1f1f1;
			margin-top: 0.4rem;
		}
	}
	.content-768 {
		width: 100%;
		height: 720px;
		position: relative;
		.inner {
			padding-top: 56px;
		}
		&-Bg {
			width: 242px;
			height: 433px;
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center center;
			position: absolute;
			right: 0;
			top: 23px;
		}
		&-title {
			font-size: 16px;
			font-family: Source Han Sans, Source Han Sans CN;
			font-weight: 700;
			color: #333333;
			line-height: 24px;
			padding: 0 12px;
		}
		&-desc {
			font-size: 14px;
			font-family: Source Han Sans, Source Han Sans CN;
			font-weight: 400;
			color: #8a8a8a;
			line-height: 22px;
			padding: 0 12px;
			margin: 12px 0;
			p {
				margin-bottom: 8px;
			}
		}
		&-data {
			width: 100%;
			height: 67px;
			border: 1px solid #f1f1f1;
		}
	}
}
@media screen and (max-width: 768px) {
	.AboutUs-warp {
		.left,
		.right {
			width: 16px;
			background-color: #fff;
		}
		.right {
			margin-left: -16px;
		}
		.inner {
			margin: 0 16px;
		}
	}
}
</style>
